<template>
    <div>
        <b-row>
            <b-col md="12">
                <statistik :jm_data=jm_data @loadDatas="loadDatas"></statistik>
            </b-col>
            <b-col md="12">
                <b-card>
                    <tabel :items=items @DetailData=DetailData @DetailDataTanggapan=DetailDataTanggapan  @EditData = EditData @DetailTanggapan = DetailTanggapan
                        @DeleteData=DeleteData></tabel>
                </b-card>
            </b-col>
        </b-row>
        <detail_tanggapan :rsDetail=rsDetail></detail_tanggapan>
        <add-pengaduan :rsDetail=rsDetail :kabupaten=kabupaten :jenis_aduan=jenis_aduan @loadDatas=loadDatas>

        </add-pengaduan>
        <editPengaduan :rsDetail=rsDetail :kabupaten=kabupaten :jenis_aduan=jenis_aduan @loadDatas=loadDatas>
        </editPengaduan>

    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';

    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'
    import editPengaduan from './component/editPengaduan.vue';
    import statistik from './component/statistik.vue'
    import tabel from './component/tabel.vue';
    import detail_tanggapan from './component/detailAduan.vue'
    import addPengaduan from './component/addPengaduan.vue';
    export default {
        mixins: [Base],
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            statistik,
            tabel,
            detail_tanggapan,
            addPengaduan,
            editPengaduan

        },
        data() {
            return {
                jm_data: {},
                items: [],
                status: '',
                rsDetail: [],
                kabupaten: [],
                jenis_aduan: []
            }
        },
        mounted() {
            this.cek_token();
            this.load_data();
            this.jm_aduan();
            this.get_kabupaten();
            this.get_jenis_aduan();
        },
        methods: {
            DetailTanggapan(){
                this.rsDetail = value;
                this.$root.$emit('bv::show::modal', 'modal-2');
            },
            EditData(value){
                this.rsDetail = value;
                this.$root.$emit('bv::show::modal', 'modal-3');
            },
            DetailData(value) {
                this.rsDetail = value;
            },
            loadDatas(value) {
                this.status = value;
                this.load_data();
                this.jm_aduan();
            },
            DetailDataTanggapan(value) {
                this.rsDetail = value;
                this.$root.$emit('bv::show::modal', 'modal-2');
            },
            async get_kabupaten() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/masyarakat/get_kabupaten',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kabupaten = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_jenis_aduan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/masyarakat/get_jenis_aduan',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.jenis_aduan = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/masyarakat/load_data',
                        data: {
                            status: self.status
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async jm_aduan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/masyarakat/jm_aduan',
                        data: {
                            status: self.status
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.jm_data = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async DeleteData(value) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/pengaduan/masyarakat/hapus_aduan',
                        data: {
                            id: value.id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('info', "Hapus Data", response.data.message);
                        self.load_data();
                        self.jm_aduan();
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        }

    }
</script>

<style>

</style>