<template>
    <b-row>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data">
                    <!-- {{ data.item }} -->
                    <b-row class="mlm-5 ">
                        <b-col md="7">
                            <h6 class="text-primary"   v-if="data.item.status =='Ditanggapi'"><strong>Judul {{ data.item.judul_aduan }}</strong></h6>

                            <h6 class="text-warning"   v-if="data.item.status !='Ditanggapi'"><strong>Judul {{ data.item.judul_aduan }}</strong></h6>


                            <h6 v-if="data.item.status !='Ditanggapi'"><small><strong class="text-warning"> kategori
                                        {{ data.item.jenis_aduan }}</strong>
                                </small></h6>
                            <h6 v-if="data.item.status =='Ditanggapi'"><small><strong class=""> Kategori
                                        {{ data.item.jenis_aduan }}</strong>
                                </small></h6>


                            <p style="text-align:justify"><small> {{ data.item.deskripsi }} </small>
                                <br><i><small>{{ data.item.nm_lengkap }} </small></i>
                            </p>

                        </b-col>
                        <b-col md="2">
                            <h6><small>Lokasi</small></h6>
                            <h6><small><strong>
                                        <feather-icon icon="MapPinIcon" size="15" /> {{ data.item.nm_wil }}
                                    </strong></small></h6>
                            <h6><small>Tanggal Pengaduan</small></h6>
                            <h6><small><strong> {{ data.item.dc }}</strong></small></h6>

                            <b-row>
                                <b-col md="12"> 

                                    <b-button  block variant="warning" size="sm"  @click="DetailData(data.item)" v-b-modal.modal-2
                                        v-if="data.item.status !='Ditanggapi'">
                                        <feather-icon icon="AlertCircleIcon" size="15" /> Belum Ditanggapi</b-button>
                                </b-col>
                            </b-row>
                            <b-button v-b-modal.modal-2 block variant="primary" size="sm"  @click="DetailData(data.item)"
                                        v-if="data.item.status =='Ditanggapi'">
                                        <feather-icon icon="CheckCircleIcon" size="15" /> Status Ditanggapi</b-button> 
                        </b-col>
                        <b-col md="3" > 
                            <!-- <span v-if="data.item.status =='Ditanggapi'"> 
                                <b-button block v-b-modal.modal-2 variant="info" size="sm"  class="p-1" @click="DetailTanggapan(data.item)">
                                    <feather-icon icon="CheckCircleIcon" size="15" /> Detail Tanggapan
                                </b-button>
                                
                                </span> -->
                            <span v-if="data.item.status !='Ditanggapi'">
                                <b-button block variant="warning" size="sm" v-b-modal.modal-3  @click="EditData(data.item)">
                                    <feather-icon icon="EditIcon" size="15" /> Edit Data
                                </b-button>

                                <b-button block variant="danger" size="sm" @click="DeleteData(data.item)">
                                    <feather-icon icon="Trash2Icon" size="15" /> Hapus Data 
                                </b-button>
                            </span>


                        </b-col>

                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Row {{ items.length }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props: {
            items: {

            }
        },
        emits:["DetailData", "DetailDataTanggapan", "DeleteData","EditData"],
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            DeleteData(value){
                if(confirm("Apakah anda yakin akan menghapus data ini..?") == true){
                    this.$emit("DeleteData", value);
                }
            },
            EditData(value){
                this.$emit("EditData", value);
            },
            DetailTanggapan(value){
                this.$emit("DetailTanggapan", value);
            },
            DetailDataTanggapan(value){
                this.$emit("DetailDataTanggapan", value);
            },
            DetailData(value){
                this.$emit("DetailData", value);
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>