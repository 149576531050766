<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-1" title="Ajukan Pengaduan Masyarakat" ok-only ok-title="Accept" hide-footer
            no-close-on-backdrop size="lg">
            <b-row>
                <b-col md="12">
                    <b-alert variant=" " show>
                        <div class="alert-body">
                            <validation-observer ref="simpleRules">
                                <b-form>
                                    <b-row>
                                        <b-col md="12">
                                            <b-form-group label="Judul Pengaduan">
                                                <validation-provider #default="{ errors }" name="Status Tanggapan "
                                                    rules="required">
                                                    <!-- basic -->
                                                    <b-form-input label="Basic Input" label-for="basicInput"
                                                        v-model="input.judul_aduan"
                                                        :state="errors.length > 0 ? false:null">
                                                    </b-form-input>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                            <b-form-group label="Jenis Pengaduan">
                                                <validation-provider #default="{ errors }" name="Jenis Pengaduan "
                                                    rules="required">
                                                    <v-select v-model="input.id_jenis_pengaduan"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        label="jenis_aduan" :options="jenis_aduan"
                                                        :state="errors.length > 0 ? false:null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-form-group label="Deskripsi">
                                                <validation-provider #default="{ errors }" name="Deskripsi"
                                                    rules="required">
                                                    <b-form-textarea id="textarea-default" v-model="input.deskripsi"
                                                        :state="errors.length > 0 ? false:null" placeholder="Deskripsi"
                                                        rows="3"></b-form-textarea>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-form-group label="Kabupaten">
                                                <validation-provider #default="{ errors }" name="Kabupaten"
                                                    rules="required">
                                                    <v-select v-model="input.id_wilayah"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        label="nm_wil" :options="kabupaten"
                                                        :state="errors.length > 0 ? false:null" />
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-row class="mb-1">
                                                <b-col md="12"><label for="textarea-default">Titik Koorinat</label>
                                                </b-col>
                                                <b-col md="2">
                                                    <b-button block variant="warning" @click="getLocation">
                                                        <feather-icon icon='MapPinIcon'></feather-icon> Get
                                                    </b-button>
                                                </b-col>
                                                <b-col md="8">
                                                    <validation-provider #default="{ errors }" name="Titik Koordinat"
                                                        rules="required">
                                                        <b-form-input v-model="coordinates.latitude"
                                                            :state="errors.length > 0 ? false:null"
                                                            placeholder="-6.9929335,110.487054125" />
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="2" v-show="coordinates.latitude !=''">
                                                    <a :href="'https://www.google.com/maps/search/?api=1&amp;query='+ coordinates.latitude"
                                                        target="_blank">
                                                        <b-button block variant="primary" @click="getLocation">
                                                            <feather-icon icon='MapIcon'></feather-icon> view
                                                        </b-button>
                                                    </a>

                                                </b-col>
                                            </b-row>
                                            <h6 v-if="input.deskripsi !=''">Lampiran Dokumen Pengaduan</h6>
                                            <hr>
                                            <b-row v-if="input.deskripsi !=''">
                                                <b-col md="6">
                                                    <b-form-group label="Foto Pengaduan 1">
                                                        <b-form-file v-model="file" ref="file" id="customFile1"
                                                            @input="uploadFile"
                                                            placeholder="Choose a file or drop it here..."
                                                            drop-placeholder="Drop file here..." />
                                                    </b-form-group>
                                                    <b-form-group label="Foto Pengaduan 3">
                                                        <b-form-file v-model="file2" ref="file2" id="customFile2"
                                                            @input="uploadFile2"
                                                            placeholder="Choose a file or drop it here..."
                                                            drop-placeholder="Drop file here..." />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col md="6" v-if="fileUpload !='' || fileUpload == null ">
                                                    <b-form-group label="Foto Pengaduan 2">
                                                        <b-form-file v-model="file3" ref="file3" id="customFile3"
                                                            @input="uploadFile3"
                                                            placeholder="Choose a file or drop it here..."
                                                            drop-placeholder="Drop file here..." />
                                                    </b-form-group>
                                                    <b-form-group label="Foto Pengaduan 4">
                                                        <b-form-file v-model="file4" ref="file4" id="customFile4"
                                                            @input="uploadFile4"
                                                            placeholder="Choose a file or drop it here..."
                                                            drop-placeholder="Drop file here..." />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>


                                        </b-col>
                                        <b-col cols="12" v-if="input.deskripsi !=''">
                                            <b-button variant="primary" type="submit" block
                                                @click.prevent="validationForm" class="mr-1 mb-1">
                                                <feather-icon icon="SaveIcon"></feather-icon> Ajukan Pengaduan
                                            </b-button>

                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import axios from '@/config/Axios'
    import {
        ref
    } from '@vue/composition-api'

    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BImg,
        BFormInput,
        BFormGroup,
        BForm,
        BCardText,
        BFormTextarea,
        BFormSelect,
        BFormFile
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required,
        email
    } from '@validations'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BButton,
            BModal,
            BAlert,
            BRow,
            BCol,
            BImg,
            BFormInput,
            FeatherIcon,
            BFormGroup,
            BForm,
            BCardText,
            BFormTextarea,
            BFormSelect,
            BFormFile,
            vSelect
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        emits: ["loadDatas"],
        props: {
            kabupaten: {

            },
            jenis_aduan: {

            }
        },
        data() {
            return {
                input: {
                    judul_aduan: "",
                    deskripsi: ""
                },
                sideImg: require('@/assets/images/pages/coming-soon.svg'),
                emailValue: '',
                name: '',
                required,
                email,
                coordinates: {
                    latitude: '',
                    longitude: ''
                },
                file: {},
                file2: {},
                file3: {},
                file4: {},
                fileUpload: '',
                fileUpload2: '',
                fileUpload3: '',
                fileUpload4: '',

            }
        },
        methods: {
            uploadFile() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                const self = this;
                axios.post('/upload/pengaduan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file = {};
                        self.fileUpload = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile2() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file2 = this.$refs.file2.files[0];
                let formData = new FormData();
                formData.append('file', this.file2);
                const self = this;
                axios.post('/upload/pengaduan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file2 = {};
                        self.fileUpload2 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile3() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file3 = this.$refs.file3.files[0];
                let formData = new FormData();
                formData.append('file', this.file3);
                const self = this;
                axios.post('/upload/pengaduan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file3 = {};
                        self.fileUpload3 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile4() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file4 = this.$refs.file4.files[0];
                let formData = new FormData();
                formData.append('file', this.file4);
                const self = this;
                axios.post('/upload/pengaduan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file4 = {};
                        self.fileUpload4 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },

            getLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.showPosition);
                } else {
                    alert("Geolocation tidak didukung di browser ini.");
                }
            },
            showPosition(position) {
                this.coordinates = {
                    latitude: position.coords.latitude + ',' + position.coords.longitude,
                    longitude: position.coords.longitude
                };
            },
            async simpanData() {
                const isAuthenticated = localStorage.getItem('token_auth');
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/pengaduan/masyarakat/insert_aduan',
                        data: {
                            judul_aduan: self.input.judul_aduan,
                            id_jenis_pengaduan: self.input.id_jenis_pengaduan.id,
                            deskripsi: self.input.deskripsi,
                            id_wilayah: self.input.id_wilayah.id,
                            koordinat: self.coordinates.latitude,
                            foto_1: self.fileUpload,
                            foto_2: self.fileUpload2,
                            foto_3: self.fileUpload3,
                            foto_4: self.fileUpload4,
                        },
                        headers: {
                            'Authorization': isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rs = response.data.result;
                        self.input = {}
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.simpanData();
                        this.$emit("loadDatas", "");
                        this.close()
                    }
                })
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-1');
            }
        },
        // setup() {
        //     const refInputEl = ref(null)
        //     const previewEl = ref(null)
        //     const {
        //         inputImageRenderer
        //     } = useInputImageRenderer(refInputEl, previewEl)
        //     return {
        //         refInputEl,
        //         previewEl,
        //         inputImageRenderer,
        //     }
        // },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>