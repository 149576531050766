<template>
    <div> 
        <b-row class="match-height">
            <b-col md="3">
                <b-card class="card-congratulations  bg-danger match-height text-white" v-b-modal.modal-1
                    @click="act_data('')">
                    <div class="row" style="margin-bottom: -25px; margin-top: -30xp;">
                        <div class="col-sm-6 col-xl-4 mb-2 mb-xl-0" style="margin-top: -10px;">
                            <div class="media">
                                <div class="media-aside mr-2 align-self-start"><span
                                        class="b-avatar badge-light-warning rounded-circle"
                                        style="width: 48px; height: 48px;">
                                        <span class="b-avatar-custom">
                                            <feather-icon size='25' icon='PlusIcon'></feather-icon>
                                        </span>
                                        <!----></span></div>
                                <div class="media-body my-auto">
                                    <h4 class="font-weight-bolder mb-0 text-white"> Ajukan pengaduan </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col md="9">
                <b-card no-body class="card-statistics">
                    <b-card-body class="statistics-body ">
                        <div class="row" style="margin-bottom: -15px; margin-top: -30xp;" >
                            <div class="col-sm-6 col-xl-4 mb-2 mb-xl-0" style="margin-top: -10px;">
                                <div class="media"  @click="loadDatas('')">
                                    <div class="media-aside mr-2 align-self-start"><span
                                            class="b-avatar badge-light-primary rounded-circle"
                                            style="width: 48px; height: 48px;">
                                            <span class="b-avatar-custom">
                                                <feather-icon size='25' icon='TwitchIcon'></feather-icon>
                                            </span>
                                            <!----></span></div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0"> {{ jm_data.jm }} </h4>
                                        <p class="card-text font-small-3 mb-0"> Total Aduan </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-xl-4 mb-2 mb-xl-0" style="margin-top: -10px;">
                                <div class="media"   @click="loadDatas('Ditanggapi')">
                                    <div class="media-aside mr-2 align-self-start"><span
                                            class="b-avatar badge-light-info rounded-circle"
                                            style="width: 48px; height: 48px;">
                                            <span class="b-avatar-custom">
                                                <feather-icon size='25' icon='CheckCircleIcon'></feather-icon>
                                            </span>
                                            <!----></span></div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0"> {{ jm_data.jm_ditanggapi }} </h4>
                                        <p class="card-text font-small-3 mb-0"> Aduan Ditanggapi </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-xl-4 mb-2 mb-xl-0" style="margin-top: -10px;" >
                                <div class="media" @click="loadDatas('Pengaduan')">
                                    <div class="media-aside mr-2 align-self-start"><span
                                            class="b-avatar badge-light-danger rounded-circle"
                                            style="width: 48px; height: 48px;">
                                            <span class="b-avatar-custom">
                                                <feather-icon size='25' icon='AlertCircleIcon'></feather-icon>
                                            </span>
                                            <!----></span></div>
                                    <div class="media-body my-auto">
                                        <h4 class="font-weight-bolder mb-0"> {{ jm_data.jm_belum_ditanggapi }} </h4>
                                        <p class="card-text font-small-3 mb-0"> Aduan Belum Ditanggapi </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col> 

        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton,
        BAvatar
    } from 'bootstrap-vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            BAvatar,
            FeatherIcon

        },
        props: {
            jm_data: {}
        },
        emits:["loadDatas"],
        methods:{
            loadDatas(value){
                this.$emit("loadDatas", value);
            }
        }


    }
</script>

<style>

</style>